<script>
import MainModal from "@/package/components/MainModal.vue";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "MarketModal",
  components: { MainButton, MainModal },

  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      link: null,
    };
  },

  mounted() {
    this.link = process.env.VUE_APP_MARKET_URL;
  },
};
</script>

<template>
  <MainModal
    class="market-modal"
    :show-modal="isShowModal"
    @close="$emit('close')"
  >
    <section>
      <h3>Получение вознаграждений за покупки структуры в RC MARKET</h3>

      <p>
        Для получения вознаграждений за месяц необходимо выполнить 2 условия:
      </p>

      <div class="market-modal__conditions conditions">
        <div class="conditions__item">
          <span>1</span>
          <p>
            Совершить покупки в
            <a :href="link" target="_blank">RC MARKET</a> за текущий месяц на
            сумму <strong>3 000 RC или более</strong>
          </p>
        </div>

        <div class="conditions__item">
          <span>2</span>
          <p>Оплатить абонентскую плату на аккаунте*</p>
        </div>

        <span class="conditions__mark">
          *На аккаунт с неоплаченной абонентской платой вознаграждение не
          начисляется.
        </span>
      </div>

      <p>
        Выполнить условия нужно <strong>с 1-го</strong> числа текущего месяца
        <strong>00:00 (МСК) по 1-е</strong> число
        <strong>00:00 (МСК)</strong> следующего месяца.
      </p>

      <p>
        Вознаграждения за текущий месяц начисляются включительно
        <strong>до 5-го</strong> числа следующего месяца.
      </p>

      <MainButton
        type="button"
        title="Понятно"
        color="dark"
        @click="$emit('close')"
      />
    </section>
  </MainModal>
</template>

<style lang="scss" scoped>
.market-modal {
  .main-modal {
    &__container {
      background: $light-second !important;
    }
  }

  section {
    max-width: 484px;
    display: flex;
    flex-direction: column;

    h3 {
      @include title-3;
      color: $dark-primary;
      margin-bottom: 24px;
    }

    p {
      @include body-1;
      color: $dark-primary;

      &:nth-child(4) {
        margin-bottom: 12px;
      }

      &:nth-child(5) {
        margin-bottom: 24px;
      }

      strong {
        @include body-1-bold;
      }
    }
  }
}

.conditions {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: $light-primary;
  margin: 24px 0;

  &__item {
    display: flex;
    gap: 12px;
    align-items: flex-start;

    &:first-child {
      margin-bottom: 12px;
    }

    &:nth-child(2) {
      margin-bottom: 20px;
      align-items: center;
    }

    span {
      display: flex;
      min-width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background: $gold-main;
      @include text-2-bold;
      color: $dark-primary;
      line-height: 1;
    }

    p {
      @include body-1;
      color: $dark-primary;

      a {
        color: $blue;
        text-decoration: none;
      }

      strong {
        @include body-1-bold;
      }
    }
  }

  &__mark {
    @include text-2;
    color: $dark-fifth;
  }
}
</style>
