<script>
import { mapActions, mapState } from "vuex";
import {
  REFILL_AMOUNTS,
  REFILL_AMOUNTS_KZT,
} from "@/package/const/refill-amounts";
import vClickOutside from "v-click-outside";
import MainButton from "@/components/helpers/MainButton.vue";
import MainSelect from "@/components/helpers/MainSelect";
import WarningPopup from "@/components/WarningPopup.vue";
// import onYaPayLoad from "@/package/usecases/yandex-pay-init";

export default {
  components: {
    MainSelect,
    MainButton,
    WarningPopup,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    tooltipPosition: {
      type: String,
      default: "top",
    },

    tariff: {
      type: Object,
    },
  },

  data() {
    return {
      amount: null,
      REFILL_AMOUNTS: REFILL_AMOUNTS,
      buttonText: "Перейти к оплате",
      refillStatus: "",
      currency: "RUB",
      paymentMethod: "sber",
    };
  },

  watch: {
    currency() {
      if (this.currency === "RUB") {
        this.amount = REFILL_AMOUNTS[0];
        this.REFILL_AMOUNTS = REFILL_AMOUNTS;
      } else {
        this.amount = REFILL_AMOUNTS_KZT[0];
        this.REFILL_AMOUNTS = REFILL_AMOUNTS_KZT;
      }

      this.checkLimitsByCurrency({ currency: this.currency });
    },

    // amount: {
    //   deep: true,
    //   handler() {
    //     document.getElementById("yandex-pay").innerHTML = "";
    //
    //     const amount = this.amount;
    //
    //     if (document.getElementById("yandexPayScript")) {
    //       onYaPayLoad({
    //         id: `#yandex-pay`,
    //         amount: `${amount.id}`,
    //         availablePaymentMethods: ["CARD"],
    //         paymentMethod: "yandexpay_card",
    //         buttonType: "Pay",
    //         buttonTheme: "Black",
    //       });
    //     } else {
    //       const yandexSplitScript = document.createElement("script");
    //
    //       yandexSplitScript.id = "yandexPayScript";
    //       yandexSplitScript.src = "https://pay.yandex.ru/sdk/v1/pay.js";
    //       yandexSplitScript.async = true;
    //       yandexSplitScript.onload = function () {
    //         onYaPayLoad({
    //           id: `#yandex-pay`,
    //           amount: `${amount.id}`,
    //           availablePaymentMethods: ["CARD"],
    //           paymentMethod: "yandexpay_card",
    //           buttonType: "Pay",
    //           buttonTheme: "Black",
    //         });
    //       };
    //
    //       document.body.appendChild(yandexSplitScript);
    //     }
    //   },
    // },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      limits: (state) => state.balance.limits,
      sberbankRefill: (state) => state.warnings.sberbankRefill,
    }),

    isDisabledButton() {
      return !this.amount;
    },

    // isShowKZT() {
    //   if (this.$route.name !== "Tariffs") {
    //     return true;
    //   } else {
    //     return this.tariff?.code === "business_d";
    //   }
    // },

    isDisableRefillButton() {
      if (this.limits !== null && this.limits?.remain) {
        return this.amount?.id > this.limits?.remain;
      } else {
        return false;
      }
    },
  },

  methods: {
    ...mapActions({
      refillBalance: "balance/refillBalance",
      checkLimitsByCurrency: "balance/checkLimitsByCurrency",
    }),

    refillBalanceRequest(amount) {
      this.refillStatus = "LOADING";
      this.buttonText = "Пополнение";

      this.refillBalance({
        amount: amount,
        currency: this.currency,
        payment_method: this.paymentMethod,
      })
        .then((response) => {
          window.open(`${response.data.redirect_url}`, "_self");
        })
        .finally(() => {
          this.refillStatus = "";
          this.buttonText = "Перейти к оплате";
        });
    },

    closeTooltip() {
      this.$emit("close");
    },
  },

  mounted() {
    this.checkLimitsByCurrency({ currency: this.currency });
  },
};
</script>

<template>
  <div
    v-click-outside="closeTooltip"
    class="tooltip-up-balance"
    :class="{
      'tooltip-up-balance_position_bottom': tooltipPosition === 'bottom',
    }"
  >
    <span class="tooltip-up-balance__close" @click="$emit('close')"></span>

    <div class="tooltip-up-balance__title">
      {{ title }}
    </div>

    <div v-if="description" class="tooltip-up-balance__description">
      {{ description }}
    </div>

    <div class="tooltip-up-balance__amount">
      <MainSelect
        :selected-option.sync="amount"
        :options="REFILL_AMOUNTS"
        style-type="background-gray"
      />
    </div>

    <div class="tooltip-up-balance__payment-method payment-method">
      <p>Выберите банк</p>

      <button
        type="button"
        class="payment-method__button"
        :class="{ 'payment-method__button_active': paymentMethod === 'card' }"
        @click="paymentMethod = 'card'"
      >
        <img src="../../assets/pay-systems/card.svg" alt="card" />
        Другой банк
      </button>

      <button
        class="payment-method__button"
        :class="{ 'payment-method__button_active': paymentMethod === 'sber' }"
        type="button"
        @click="paymentMethod = 'sber'"
      >
        <img src="../../assets/pay-systems/sber.svg" alt="sber" />
        Сбербанк
      </button>
    </div>

    <WarningPopup
      v-if="sberbankRefill"
      class="tooltip-up-balance__warning"
      :content="sberbankRefill.content"
    />

    <!--    <div class="tooltip-up-balance__refill-types">
      <BalanceRefillType
        v-model="currency"
        :currency="'RUB'"
        :text="'Банковской картой РФ'"
        :country="'russia'"
      />
      &lt;!&ndash;      <BalanceRefillType
        v-if="isShowKZT"
        v-model="currency"
        :currency="'KZT'"
        :text="'Банковской картой Казахстана'"
        :country="'kzt'"
      />&ndash;&gt;
    </div>-->

    <div class="tooltip-up-balance__button">
      <MainButton
        :title="buttonText"
        color="gold"
        :disabled="isDisabledButton || isDisableRefillButton"
        @click="refillBalanceRequest(amount.id)"
      />

      <p v-if="isDisableRefillButton" class="tooltip-up-balance__limit-error">
        Превышен лимит
      </p>

      <div id="yandex-pay" class="tooltip-up-balance__yandex-pay"></div>
    </div>

    <div class="tooltip-up-balance__systems">
      <img src="../../assets/pay-systems/visa.svg" alt="visa" />
      <img src="../../assets/pay-systems/mastercard.svg" alt="mastercard" />
      <img src="../../assets/pay-systems/mir.svg" alt="mir" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.tooltip-up-balance {
  width: 328px;
  padding: $space-l;
  background-color: $light-primary;
  border-radius: 8px;
  position: absolute;
  bottom: calc(100% + 10px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 999;

  @media (max-width: 576px) {
    width: 280px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    top: 100%;
    transform: scaleY(-1);
    border: 8px solid transparent;
    border-bottom: 8px solid $light-primary;
  }

  &_position_bottom {
    bottom: auto;
    top: calc(100% + 10px);

    &:before {
      bottom: 100%;
      top: auto;
      transform: scaleY(1);
    }
  }

  &__close {
    position: absolute;
    right: $space-l;
    top: $space-l;
    cursor: pointer;
    mask: url("../../assets/icons/close_bold.svg") 0 0 / cover no-repeat;
    min-width: 24px;
    height: 24px;
    display: inline-block;
    background: $light-sixth;
  }

  &__title {
    @include body-1-bold;
    color: $dark-primary;
    width: calc(100% - 32px);
  }

  &__description {
    @include text-2;
    color: $dark-fifth;
    margin: $space-xs 0 0;
    width: calc(100% - 24px);
  }

  &__amount {
    margin: 24px 0 24px 0;

    .main-select {
      width: 100%;
    }
  }

  &__warning {
    margin: 24px 0;
  }

  //&__refill-types {
  //  margin-top: 24px;
  //  display: flex;
  //  flex-direction: column;
  //  gap: 8px;
  //}

  &__button {
    margin: 24px 0 0;

    ::v-deep .tooltip-up-balance__yandex-pay .ya-pay-button {
      height: 52px !important;
      margin-top: 8px !important;
    }
  }

  &__limit-error {
    color: $red;
    @include caption-1;
    margin-top: 8px;
  }

  &__systems {
    margin: 24px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

.payment-method {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;

  p {
    @include text-2;
    color: $dark-fifth;
  }

  &__button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid $light-third;
    background: $light-second;

    &:hover {
      border: 1px solid $light-fifth;
    }

    &:active,
    &_active {
      border: 1px solid $dark-primary !important;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
