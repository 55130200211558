var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-input"},[(_vm.label)?_c('label',{attrs:{"for":"input"}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.requiredIcon)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('input',{directives:[{name:"maska",rawName:"v-maska",value:(_vm.mask),expression:"mask"}],staticClass:"main-input__input",class:[
      {
        'main-input__input_error': _vm.error,
        'main-input__input_dashed': _vm.useDashedBorder,
      },
      `main-input__input_${_vm.background}`,
      { 'main-input__input_offset-right': _vm.isOffsetRight },
    ],attrs:{"id":"input","type":_vm.type,"placeholder":_vm.placeholder,"required":_vm.required,"readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"focusout":function($event){return _vm.$emit('focusout', $event.target.value)}}}),(_vm.error)?_c('span',{staticClass:"main-input__error",class:{ 'main-input__error_margin': _vm.errorMessage }},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_vm._t("default"),_vm._t("icon")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }