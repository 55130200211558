<script>
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";

export default {
  components: {
    ButtonRedirect,
  },

  methods: {
    redirectToChat() {
      window.open("https://t.me/+8x-2UuPv0nU1MzZi");
    },
  },
};
</script>

<template>
  <div class="telegram-chat-news">
    <div class="telegram-chat-news__wrapper">
      <h4 class="telegram-chat-news__title">
        Актуальные новости RC&nbsp;Group всегда под рукой!
      </h4>
      <p class="telegram-chat-news__text">
        Присоединяйся к нашему Telegram-каналу
      </p>

      <button-redirect
        class="telegram-chat-news__link"
        :arrow-position="'right'"
        :text="'Открыть в Telegram'"
        :button-style="'bold'"
        @click="redirectToChat"
      />
    </div>

    <div class="telegram-chat-news__img">
      <img src="../assets/images/telegram_chat_news.png" alt="" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.telegram-chat-news {
  background: $light-primary;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  &__wrapper {
    padding: 16px 0 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__img {
    display: flex;
    align-items: center;

    > img {
      width: 120px;
    }
  }

  &__title {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: 4px;
  }

  &__text {
    @include caption-1;
    color: $dark-primary;
    margin-bottom: 16px;
  }

  &__link {
    margin-top: auto;
  }
}

@media (max-width: 576px) {
  .telegram-chat-news {
    &__img {
      max-width: 100px;
      min-width: 100px;

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 375px) {
  .telegram-chat-news {
    &__img {
      max-width: 94px;
      min-width: 94px;
    }
  }
}
</style>
