<script>
import {
  EMAIL_PATTERN,
  SPECIAL_SYMBOLS_NEXT_LINE,
} from "@/package/const/patterns-regex";

export default {
  props: {
    content: {
      type: String,
      default: "",
    },

    title: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "default",
    },
  },

  data() {
    return {
      formattedContent: "",
      classModifier: "",
    };
  },

  computed: {},

  methods: {
    replaceWithEmail(email) {
      return `<a href="mailto:${email}">${email}</a>`;
    },

    replaceWithSpecialSymbols() {
      return "<br/>";
    },

    countLines() {
      let element = this.$refs.content;
      let fontSize = parseFloat(getComputedStyle(element).fontSize);
      let numberOfLines = element.scrollHeight / fontSize;

      return Math.floor(numberOfLines);
    },

    // countLines() {
    //   // const block = this.$refs.content; //  Получаем ссылку на элемент
    //   // const lineHeight = parseFloat(window.getComputedStyle(block).lineHeight); // Получаем высоту строки
    //   // const blockHeight = block.clientHeight; // Получаем высоту блока
    //   // console.log(Math.floor(blockHeight / lineHeight));
    //   // // this.lineCount = Math.floor(blockHeight / lineHeight); // Вычисляем количество строк
    //   let element = this.$refs.content;
    //   let fontSize = parseFloat(getComputedStyle(element).fontSize);
    //   let numberOfLines = element.scrollHeight / fontSize;
    //   // document.getElementById("result").innerHTML = numberOfLines;
    //   console.log(Math.floor(numberOfLines));
    // },
  },

  mounted() {
    let result = "";
    result = this.content.replace(EMAIL_PATTERN, this.replaceWithEmail);

    result = result.replace(
      SPECIAL_SYMBOLS_NEXT_LINE,
      this.replaceWithSpecialSymbols
    );

    // const ooo = sanitizeHtml(result, {
    //   allowedTags: ["b", "i", "em", "strong", "a"],
    //   allowedAttributes: {
    //     a: ["href"],
    //   },
    //   allowedIframeHostnames: ["www.youtube.com"],
    // });
    this.formattedContent = this.$sanitize(result);
  },

  updated() {
    // console.log(this.formattedContent, this.$refs.content.getClientRects());
    // console.log(this.countLines);
    this.classModifier =
      this.countLines() === 1 ? "ui-element-warning_center" : "";
  },
};
</script>

<template>
  <div class="ui-element-warning" :class="classModifier">
    <div class="ui-element-warning__icon">
      <span class="icon-warning" />
    </div>

    <div
      ref="content"
      class="ui-element-warning__text"
      v-html="this.formattedContent"
    ></div>
  </div>
</template>

<style lang="scss">
.ui-element-warning {
  display: flex;
  gap: 8px;
  border-radius: 12px;
  padding: 16px;
  background: $services-market;
  min-height: 56px;

  &_center {
    align-items: center;
  }

  &__icon {
    > span {
      width: 24px;
      height: 24px;
      background: $red;
    }
  }

  &__text {
    word-break: break-word;
    @include text-2;

    > a {
      color: $blue;
      text-decoration: none;
    }
  }
}
</style>
