<script>
import { mapActions } from "vuex";
import MainButton from "@/components/helpers/MainButton";
import MainInputRadioGroup from "@/components/helpers/MainInputRadioGroup";
import MainInputCheckboxGroup from "@/components/helpers/MainInputCheckboxGroup";
import MainTextarea from "@/components/helpers/MainTextarea";
import MainInput from "@/components/helpers/MainInput";
import PollResults from "@/components/poll/PollResults";
import MainButtonExpand from "@/components/helpers/MainButtonExpand.vue";

export default {
  components: {
    PollResults,
    MainInput,
    MainTextarea,
    MainInputCheckboxGroup,
    MainInputRadioGroup,
    MainButton,
    MainButtonExpand,
  },

  props: {
    poll: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      selectedOptions: [],
      customAnswer: "",

      showResults: true,
    };
  },

  computed: {
    formattedOptions() {
      if (this.poll.has_custom_answer) {
        return [
          ...this.poll.variants,
          { id: "custom", text: "Свой вариант ответа" },
        ];
      } else {
        return this.poll.variants;
      }
    },

    showRadioButtons() {
      return this.poll.variants.length > 0 && this.poll.type === "single";
    },

    showCheckboxButtons() {
      return this.poll.variants.length > 0 && this.poll.type === "multiple";
    },

    showTextarea() {
      return this.poll.has_custom_answer && this.poll.variants.length === 0;
    },

    showInput() {
      return (
        this.hasSelectedOptionOfCustomAnswer && this.poll.has_custom_answer
      );
    },

    disabledButton() {
      return !(this.selectedOptions.length > 0 || this.customAnswer);
    },

    hasSelectedOptionOfCustomAnswer() {
      return this.selectedOptions.some((option) => {
        return option.text === "Свой вариант ответа";
      });
    },

    formattedButtonText() {
      return this.showTextarea ? "Ответить" : "Голосовать";
    },
  },

  methods: {
    ...mapActions({
      answerThePoll: "poll/answerThePoll",
    }),

    sendAnswer() {
      const payload = this.setAnswerData();

      this.answerThePoll(payload).then(() => {
        this.$emit("updatePolls");
      });
    },

    setAnswerData() {
      let payload = {
        pollId: this.poll.id,
        data: {},
      };

      if (
        (this.customAnswer && this.hasSelectedOptionOfCustomAnswer) ||
        (this.customAnswer && this.poll.variants.length === 0)
      ) {
        payload.data.custom = this.customAnswer;
      }

      let array = [];

      this.selectedOptions.forEach((option) => {
        if (option.id !== "custom") {
          array.push(option.id);
        }
      });

      if (array.length > 0) {
        payload.data.variants = array;
      }

      return payload;
    },
  },

  mounted() {
    if (this.poll.is_answered) {
      this.showResults = false;
    }
  },
};
</script>

<template>
  <div class="poll">
    <h5 class="poll__question">{{ poll.title }}</h5>

    <p v-if="poll.description && showResults" class="poll__description">
      {{ poll.description }}
    </p>

    <template v-if="!poll.is_answered">
      <div class="poll__wrapper">
        <main-input-checkbox-group
          v-if="showCheckboxButtons"
          :selected-options.sync="selectedOptions"
          :options="formattedOptions"
        />

        <main-input-radio-group
          v-if="showRadioButtons"
          v-model="selectedOptions"
          :name="poll.title"
          :options="formattedOptions"
        />

        <main-textarea
          v-if="showTextarea"
          v-model="customAnswer"
          placeholder="Введите ответ"
          :height="112"
        />

        <main-input
          v-if="showInput"
          v-model="customAnswer"
          placeholder="Введите свой вариант"
        />
      </div>

      <div class="poll__button">
        <main-button
          :title="formattedButtonText"
          color="dark"
          :disabled="disabledButton"
          @click="sendAnswer"
        />
      </div>
    </template>

    <template v-else-if="showResults">
      <poll-results :poll="poll" />
    </template>

    <main-button-expand
      v-if="poll.is_answered"
      class="poll__button-expand"
      :class="{ 'poll__button-expand_expanded': showResults }"
      text="Показать ответы"
      expanded-text="Скрыть ответы"
      :expanded.sync="showResults"
    />
  </div>
</template>

<style lang="scss" scoped>
.poll {
  background-color: $light-primary;
  padding: $space-xl;
  border-radius: $space-s;
  max-width: 680px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: $space-m;
  }

  &__question {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: $space-s;
  }

  &__description {
    @include text-2;
    color: $dark-third;
  }

  &__wrapper {
    margin-top: $space-l;

    .main-input {
      margin-top: $space-s;
    }
  }

  &__button {
    margin-top: $space-l;
    max-width: 130px;
  }

  &__button-expand {
    margin-top: $space-m;

    &_expanded {
      margin-top: $space-l;
    }
  }

  .poll-results {
    margin-top: $space-l;
  }
}
</style>
