import { DISTRIBUTOR_TARIFFS } from "@/package/const/distributor-tariffs";

export default function getTariffsListOfBinaryAccount(tariffsOfBinaryAccount) {
  let tariffs = [];

  if (tariffsOfBinaryAccount.right) {
    tariffsOfBinaryAccount.right.forEach((item) => {
      if (
        !tariffs.find((tariff) => tariff.tariffCode === item.tariff) &&
        DISTRIBUTOR_TARIFFS[item.tariff]
      ) {
        tariffs.push(DISTRIBUTOR_TARIFFS[item.tariff]);
      }
    });
  }

  if (tariffsOfBinaryAccount.left) {
    tariffsOfBinaryAccount.left.forEach((item) => {
      if (
        !tariffs.find((tariff) => tariff.tariffCode === item.tariff) &&
        DISTRIBUTOR_TARIFFS[item.tariff]
      ) {
        tariffs.push(DISTRIBUTOR_TARIFFS[item.tariff]);
      }
    });
  }

  tariffs = sortTariffs(tariffs);

  if (tariffs.length > 0) {
    return tariffs;
  } else {
    return [{ tariffCode: "-", tariffName: "-" }];
  }
}

function sortTariffs(tariffs) {
  return tariffs.sort((currentItem, nextItem) => {
    if (currentItem?.id < nextItem?.id) {
      return -1;
    }
    if (currentItem?.id > nextItem?.id) {
      return 1;
    }

    return 0;
  });
}
