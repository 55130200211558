<script>
import { mapState, mapActions } from "vuex";
import QualificationProgress from "@/components/qualifications/QualificationProgress.vue";
import LegSelector from "@/components/LegSelector.vue";
import TariffsOfBinaryAccount from "@/components/TariffsOfBinaryAccount.vue";
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";

export default {
  components: {
    QualificationProgress,
    LegSelector,
    TariffsOfBinaryAccount,
    ButtonRedirect,
  },

  data() {
    return {
      isLoadingQualifications: true,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      qualification: (state) => state.qualification.qualification,
      tariffsOfBinaryAccount: (state) => state.account.tariffsOfBinaryAccount,
      invitedTSPCount: (state) => state.qualification.invitedTSPCount,
    }),
  },

  watch: {
    selectedBinaryAccount() {
      this.loadInfo();
    },
  },

  methods: {
    ...mapActions({
      loadQualification: "qualification/loadQualification",
      loadTariffsOfBinaryAccount: "account/loadTariffsOfBinaryAccount",
      loadInvitedTSPCount: "qualification/loadInvitedTSPCount",
    }),

    loadInfo() {
      this.isLoadingQualifications = true;

      this.loadQualification({
        binaryAccount: this.selectedBinaryAccount.id,
      }).then(() => {
        this.loadInvitedTSPCount();

        this.isLoadingQualifications = false;
      });

      this.loadTariffsOfBinaryAccount(this.selectedBinaryAccount.id);
    },
  },

  mounted() {
    if (this.selectedBinaryAccount) {
      this.loadInfo();
    }
  },
};
</script>

<template>
  <div class="account-info info__wrapper">
    <div class="account-info__left info__left">
      <template v-if="selectedBinaryAccount">
        <div class="bill">
          <div class="bill__cv">
            <h6>Счет CV</h6>
            <p>
              {{ selectedBinaryAccount.cv_left }}/{{
                selectedBinaryAccount.cv_right
              }}
              <span>CV</span>
            </p>
          </div>

          <div class="bill__pv">
            <h6>Счет PV</h6>
            <p>{{ selectedBinaryAccount.pv }} <span>PV</span></p>
          </div>
        </div>

        <div class="leg-selector">
          <LegSelector />
        </div>
      </template>

      <div
        v-if="!isLoadingQualifications && qualification"
        class="account-info__qualification-progress"
      >
        <QualificationProgress
          :qualification="qualification"
          :invited-tsp-count="invitedTSPCount"
        />

        <ButtonRedirect
          text="Мои квалификации"
          arrow-position="right"
          @click="$router.push({ name: 'Qualifications' })"
        />
      </div>
    </div>

    <div class="account-info__right">
      <TariffsOfBinaryAccount
        v-if="tariffsOfBinaryAccount"
        :tariffs-of-binary-account="tariffsOfBinaryAccount"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.account-info {
  display: grid;
  grid-template-columns: 1fr minmax(180px, 240px);
  column-gap: 96px;
  row-gap: 32px;

  &__qualification-progress {
    .button-redirect {
      margin-top: 8px;
    }
  }

  &__left {
    .leg-selector {
      margin-bottom: 32px;
    }

    @media (max-width: 1024px) {
      margin-right: 0;
      width: 100%;
    }
  }

  .bill {
    display: flex;
    gap: 32px;
    margin-bottom: 32px;

    &__cv,
    &__pv {
      h6 {
        @include text-2;
        margin-bottom: 2px;
        color: $light-sixth;
      }

      p {
        @include text-1;
        color: $dark-primary;
      }
    }

    &__cv span {
      color: $money-cv;
    }

    &__pv span {
      color: $money-pv;
    }
  }
}

@media (max-width: 1280px) {
  .account-info {
    grid-template-columns: minmax(300px, 340px);
  }
}

@media (max-width: 375px) {
  .account-info {
    grid-template-columns: auto;
  }
}
</style>
