export const NEW_USERS_STATISTIC_BLOCKS = [
  {
    id: "day",
    label: "Сегодня",
    icon: "today-thin",
    value: 0,
  },
  {
    id: "week",
    label: "Неделя",
    icon: "calendar-view-week-thin",
    value: 0,
  },
  {
    id: "month",
    label: "Месяц",
    icon: "calendar-view-month-thin",
    value: 0,
  },
  {
    id: "year",
    label: "Год",
    icon: "calendar-month-thin",
    value: 0,
  },
];
