<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { addMinutes, addSeconds, format, lastDayOfMonth } from "date-fns";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import RcMarketShoppingProgress from "@/components/dashboard/RcMarketShoppingProgress.vue";
import TariffPaymentProgress from "@/components/dashboard/TariffPaymentProgress.vue";
import MarketModal from "@/components/dashboard/MarketModal.vue";

export default {
  name: "RcMarket",
  components: {
    MarketModal,
    TariffPaymentProgress,
    RcMarketShoppingProgress,
    MainButtonIcon,
  },

  data() {
    return {
      account: {
        master: "основного",
        left: "левого",
        right: "правого",
      },
      isShowModal: false,
      isLoader: false,
      timeRemaining: "",
      interval: null,
    };
  },

  watch: {
    position() {
      this.checkSubscriptions();
      this.changeLocalStorageData();

      if (document.getElementById("TariffPaymentProgress")) {
        document
          .getElementById("TariffPaymentProgress")
          .classList.remove("content__base-block_active");
      }
    },

    isShowAnimation(newValue) {
      if (!newValue) {
        return;
      }

      this.showAnimation();
    },
  },

  computed: {
    ...mapState({
      marketConditionFulfilled: (state) =>
        state.market.marketConditionFulfilled,
      tariffConditionFulfilled: (state) =>
        state.market.tariffConditionFulfilled,
      marketSpent: (state) => state.market.marketSpent,
      subscriptions: (state) => state.market.subscriptions,
      position: (state) => state.auth.selectedBinaryAccount.position,
      isShowAnimation: (state) => state.market.isShowAnimation,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    subText() {
      if (!this.marketConditionFulfilled) {
        return "<p>Выполните все условия, чтобы получить вознаграждение</p>";
      }

      if (
        this.subscriptions.filter((account) => !account.is_active).length === 0
      ) {
        return "<p>Условия выполнены на всех аккаунтах! </p> <p> Ожидайте начисление вознаграждений.</p>";
      } else {
        if (this.tariffConditionFulfilled) {
          return `<p>Условия ${
            this.account[this.position]
          } аккаунта выполнены! </p> <p>Выполните условия на всех аккаунтах, чтобы увеличить вознаграждение.</p>`;
        } else {
          return "<p>Выполните все условия, чтобы получить вознаграждение</p>";
        }
      }
    },

    showSteps() {
      return !this.tariffConditionFulfilled || !this.marketConditionFulfilled;
    },
  },

  methods: {
    ...mapActions({
      loadPersonalMarketStat: "market/loadPersonalMarketStat",
      checkSubscriptions: "market/checkSubscription",
      changeLocalStorageData: "market/changeLocalStorageData",
    }),

    ...mapMutations({
      SET_ANIMATION: "market/SET_ANIMATION",
    }),

    updateTimer() {
      const now = new Date();
      const endOfMonth = addMinutes(addSeconds(lastDayOfMonth(now), 59), 1439);
      const timeDiff = endOfMonth - now;

      if (timeDiff > 0) {
        const day = Math.floor(
          (timeDiff / (1000 * 60 * 60 * 24)) % format(endOfMonth, "dd")
        );
        const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);

        this.timeRemaining =
          day > 0 ? `${day} д.` : ` ${hours} ч. ${minutes} м.`;
      } else {
        this.timeRemaining = "Месяц закончился";
      }
    },

    showAnimation() {
      let callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("content__base-block_active");
            this.SET_ANIMATION(false);
            observer.disconnect();
          }
        });
      };

      let options = {
        root: document.getElementById("rc-market__timer"),
        rootMargin: "20px",
        threshold: 1,
      };

      let observer = new IntersectionObserver(callback, options);

      let market =
        this.marketConditionFulfilled &&
        localStorage.getItem(`marketCondition`) !== "done";

      let tariff =
        this.tariffConditionFulfilled &&
        localStorage.getItem(`${this.selectedBinaryAccount.id}`) !== "done";

      if (
        (market && this.tariffConditionFulfilled) ||
        (tariff && this.marketConditionFulfilled)
      ) {
        localStorage.setItem(`marketCondition`, "done");
        localStorage.setItem(`${this.selectedBinaryAccount.id}`, "done");
        let target = document.getElementById("ConditionCounter");
        observer.observe(target);
      } else if (market) {
        localStorage.setItem(`marketCondition`, "done");
        let target = document.getElementById("RcMarketShoppingProgress");
        observer.observe(target);
      } else if (tariff) {
        let target = document.getElementById("TariffPaymentProgress");
        observer.observe(target);
        localStorage.setItem(`${this.selectedBinaryAccount.id}`, "done");
      }
    },
  },

  beforeMount() {
    this.loadPersonalMarketStat().then(() => {
      if (
        localStorage.getItem("marketCondition") === "done" &&
        !this.marketConditionFulfilled
      ) {
        localStorage.removeItem("marketCondition");
      }
    });
  },

  mounted() {
    this.updateTimer();
    this.interval = setInterval(this.updateTimer, 30000); // Обновляем каждую минуту
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<template>
  <section class="rc-market">
    <header>
      <h5>Вознаграждение за RC MARKET</h5>

      <MainButtonIcon @click="isShowModal = true">
        <span class="icon-info" />
      </MainButtonIcon>

      <div v-html="subText" />
    </header>

    <div class="rc-market__content content">
      <div
        id="ConditionCounter"
        class="content__base-block content__conditions-counter"
      >
        <p>Выполнено условий:</p>
        <p
          :class="`${
            marketConditionFulfilled && tariffConditionFulfilled ? 'done' : ''
          }`"
        >
          {{ marketConditionFulfilled + tariffConditionFulfilled }} из 2
        </p>
        <i v-for="i in 50" :key="i" />
      </div>

      <RcMarketShoppingProgress
        v-if="showSteps"
        id="RcMarketShoppingProgress"
        class="content__base-block"
        content__base-block
        :done="marketConditionFulfilled"
      />

      <TariffPaymentProgress
        v-if="showSteps"
        id="TariffPaymentProgress"
        class="content__base-block"
        :done="tariffConditionFulfilled"
      />

      <MarketModal :is-show-modal="isShowModal" @close="isShowModal = false" />
    </div>

    <div class="rc-market__timer">
      <span class="icon-schedule" />
      <p>
        До начисления: <b>{{ timeRemaining }}</b>
      </p>
    </div>
  </section>
</template>

<style scoped lang="scss">
.rc-market {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  background: $light-second;
  margin-bottom: 32px;

  header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 20px;
    column-gap: 4px;
    row-gap: 8px;

    h5 {
      @include body-1-bold;
      color: $dark-primary;
    }

    .icon-info {
      width: 20px;
      height: 20px;
      background: $dark-primary;
    }

    ::v-deep p {
      grid-row: 2/2;
      grid-column: -1/1;
      @include body-1;

      &:first-of-type {
        margin-bottom: 8px;
      }
    }
  }

  &__timer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;

    p {
      @include text-2;
    }

    span {
      width: 16px;
      height: 16px;
      background: $dark-primary;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  &__base-block {
    padding: 16px;
    border-radius: 12px;
    background: $light-primary;
    color: $dark-primary;
    border: none;
    transition: box-shadow 0.1s ease-out;
    position: relative;

    &:not(:first-child) {
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 30px 0 rgba(169, 169, 169, 0.15);
      }
    }

    &_active {
      @for $i from 1 through 50 {
        &::v-deep i:nth-of-type(#{$i}) {
          transform: translate3d(
              random(190) - 100 + px,
              random(200) - 100 + px,
              0
            )
            rotate(random(360) + deg);
          background: hsla(random(360), 100%, 50%, 1);
          animation: bang 700ms ease-out forwards;
          opacity: 0;
        }
      }
    }

    &::v-deep i {
      position: absolute;
      display: block;
      left: 50%;
      top: 0;
      width: 5px;
      height: 10px;
      background: red;
      opacity: 0;
    }
  }

  &__conditions-counter {
    display: flex;
    justify-content: space-between;
    gap: 4px;

    p:first-of-type {
      @include text-2;
    }

    p:last-of-type {
      @include text-2-bold;
    }
  }
}

.done {
  color: $money-cv;
}

@keyframes bang {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@media (min-width: 425px) {
  .rc-market {
    margin-bottom: 48px;
  }
}

@media (min-width: 900px) {
  .rc-market {
    margin-bottom: 64px;
    max-width: 480px;
  }
}

@media (min-width: 1024px) {
  .rc-market {
    padding: 32px;
    gap: 24px;
    max-width: 520px;
  }
}
</style>
