<script>
import MainButton from "@/components/helpers/MainButton.vue";
import TooltipUpBalance from "@/components/tooltips/TooltipUpBalance.vue";
import { mapActions } from "vuex";

export default {
  components: { TooltipUpBalance, MainButton },

  props: {
    account: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isShowRefillTooltip: false,
    };
  },

  computed: {
    formattedSummaryActive() {
      return `${this.account.summary_active.toLocaleString("ru-RU")} RC`;
    },

    formattedSummaryHold() {
      return `${this.account.summary_hold.toLocaleString("ru-RU")} RC`;
    },
  },

  methods: {
    ...mapActions({
      getInfoWarningSberbankRefill: "warnings/getInfoWarningSberbankRefill",
    }),
  },

  mounted() {
    this.getInfoWarningSberbankRefill();
  },
};
</script>

<template>
  <div class="balance-block">
    <h4 class="balance-block__title">Баланс</h4>

    <h2 class="balance-block__current-balance">{{ formattedSummaryActive }}</h2>

    <p class="balance-block__pending-balance">
      В ожидании: {{ formattedSummaryHold }}
    </p>

    <div class="balance-block__wrapper">
      <MainButton
        class="balance-block__button"
        :title="'Пополнить'"
        :color="'gold'"
        @click="isShowRefillTooltip = true"
      />

      <TooltipUpBalance
        v-if="isShowRefillTooltip"
        title="Пополнение баланса"
        description="Пополните баланс через банковскую карту"
        :tooltip-position="'bottom'"
        @close="isShowRefillTooltip = false"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-block {
  background-color: $light-primary;
  border-radius: 8px;
  padding: 24px;
  max-width: 337px;
  background-image: url("../../assets/images/balance_block_background.png");
  background-position: right bottom;
  background-size: 204px auto;
  background-repeat: no-repeat;
  width: 100%;

  &__title {
    @include body-1;
    color: $dark-primary;
    margin-bottom: 24px;
  }

  &__current-balance {
    @include title-3;
    color: $dark-primary;
    margin-bottom: 4px;
  }

  &__pending-balance {
    @include text-2;
    color: $dark-fifth;
    margin-bottom: 24px;
  }

  &__wrapper {
    position: relative;
  }

  &__button {
    max-width: 160px;
  }
}

@media (max-width: 1024px) {
  .balance-block {
    max-width: none;
  }
}

@media (max-width: 900px) {
  .balance-block {
    &__title {
      margin-bottom: 16px;
    }

    &__pending-balance {
      margin-bottom: 16px;
    }
  }
}

@media (max-width: 690px) {
  .balance-block {
    background-size: 180px auto;
  }
}
</style>
