export const DISTRIBUTOR_TARIFFS = {
  smart_c: {
    id: 1,
    tariffName: "Клиент Смарт",
    tariffCode: "smart_c",
  },
  standard_c: {
    id: 2,
    tariffName: "Клиент Стандарт",
    tariffCode: "standard_c",
  },
  business_d: {
    id: 3,
    tariffName: "Бизнес",
    tariffCode: "business_d",
  },
  premium_d: {
    id: 4,
    tariffName: "Премиум",
    tariffCode: "premium_d",
  },
  vip_d: {
    id: 5,
    tariffName: "Франшиза",
    tariffCode: "vip_d",
  },
  vip_gold_d: {
    id: 6,
    tariffName: "Франшиза Голд",
    tariffCode: "vip_gold_d",
  },
};
