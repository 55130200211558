<script>
export default {
  props: {
    selectedOptions: {
      type: Array,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },
  },

  methods: {
    checkOption(option) {
      return this.selectedOptions.includes(option);
    },

    selectOption(option) {
      let updatedOptions = [...this.selectedOptions];

      if (this.checkOption(option)) {
        const index = updatedOptions.findIndex((updatedOption) => {
          return updatedOption.id === option.id;
        });

        updatedOptions.splice(index, 1);
      } else {
        updatedOptions.push(option);
      }
      this.$emit("update:selectedOptions", updatedOptions);
    },
  },
};
</script>

<template>
  <div class="main-input-checkbox-group">
    <div
      v-for="option in options"
      :key="option.id"
      class="main-input-checkbox-group__item item"
    >
      <input
        :id="option.id"
        type="checkbox"
        :checked="checkOption(option)"
        @input="selectOption(option)"
      />
      <label :for="option.id">{{ option.text }}</label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-input-checkbox-group {
  &__item {
    &:not(:last-child) {
      margin-bottom: $space-s;
    }
  }

  .item {
    position: relative;

    input {
      position: absolute; // take it out of document flow
      opacity: 0; // hide it

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        display: grid;
        grid-template-columns: 16px 1fr;
        align-items: flex-start;
        grid-gap: $space-xs;
      }

      // Box.
      & + label:before {
        content: "";
        margin-right: $space-s;
        display: inline-block;
        width: 16px;
        height: 16px;
        border: 2px solid $dark-primary;
        border-radius: $space-xxs;
      }

      // Box hover
      &:hover + label:before {
        //background: #f35429;
      }

      // Box focus
      &:focus + label:before {
        //box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      // Box checked
      &:checked + label:before {
        //background: #f35429;
      }

      // Disabled state label.
      &:disabled + label {
        //color: #b8b8b8;
        cursor: auto;
      }

      // Disabled box.
      &:disabled + label:before {
        box-shadow: none;
        //background: #ddd;
      }

      // Checkmark. Could be replaced with an image
      &:checked + label:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 7px;
        background: $dark-primary;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 $dark-primary, 4px 0 0 $dark-primary,
          4px -2px 0 $dark-primary, 4px -4px 0 $dark-primary,
          4px -6px 0 $dark-primary, 4px -7px 0 $dark-primary;
        transform: rotate(45deg);
      }
    }

    label {
      @include text-2;
      color: $dark-primary;
    }
  }
}
</style>
