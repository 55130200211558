<script>
import { mapState, mapActions } from "vuex";
import { NEW_USERS_STATISTIC_BLOCKS } from "@/package/const/new-users-statistic-blocks";
import { format } from "date-fns";
import MainButton from "@/components/helpers/MainButton.vue";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";

export default {
  components: {
    MainButtonIcon,
    MainButton,
  },

  data() {
    return {
      NEW_USERS_STATISTIC_BLOCKS,
      lastUpdate: "",
      premiumTariffs: ["business_d", "premium_d", "vip_d", "vip_gold_d"],
    };
  },

  watch: {
    selectedBinaryAccount: {
      deep: true,
      handler() {
        this.loadStatistic();
      },
    },
  },

  computed: {
    ...mapState({
      tariff: (state) => state.auth.user.tariff.code,
      invitedPersonsStat: (state) => state.account.invitedPersonsStat,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    updatedDate() {
      if (!this.lastUpdate) return;

      return format(new Date(this.lastUpdate), "dd.MM.yyyy, HH:mm");
    },

    countOfGenerations() {
      switch (this.selectedBinaryAccount?.tariff?.code) {
        case "business_d":
          return 6;
        case "premium_d":
          return 12;
        case "vip_d":
          return 16;
        case "vip_gold_d":
          return 16;
        default:
          return 6;
      }
    },

    checkTariff() {
      return this.premiumTariffs.includes(this.tariff);
    },
  },

  methods: {
    ...mapActions({
      loadInvitedPersonsStat: "account/loadInvitedPersonsStat",
      downloadInvitedPersonsStat: "account/downloadInvitedPersonsStat",
    }),

    loadStatistic() {
      this.loadInvitedPersonsStat(this.selectedBinaryAccount.id).then(
        (response) => {
          this.lastUpdate = response.data.meta.last_update;

          NEW_USERS_STATISTIC_BLOCKS.forEach((item) => {
            item.value = this.invitedPersonsStat[`${item.id}_count`];
          });
        }
      );
    },

    downloadStatistic() {
      this.downloadInvitedPersonsStat(this.selectedBinaryAccount.id);
    },
  },

  mounted() {
    this.loadStatistic();
  },
};
</script>

<template>
  <div v-if="checkTariff" class="new-users-statistic">
    <div class="new-users-statistic__title">
      <h2>
        Приглашено пользователей
        <VPopover
          trigger="hover"
          offset="4"
          placement="auto"
          popover-class="new-users-statistic__popover"
        >
          <MainButtonIcon class="new-users-statistic__button-info">
            <span class="icon-info" />
          </MainButtonIcon>
          <template #popover>
            <p class="new-users-statistic__popover-text">
              Учитываются все приглашенные пользователи, включая приглашенных от
              ТСП
            </p>
          </template>
        </VPopover>
      </h2>

      <MainButton
        :title="'Поделиться'"
        :color="'blue'"
        @click="downloadStatistic"
      />
    </div>

    <p class="new-users-statistic__caption">
      Приглашенные до {{ countOfGenerations }} поколения
    </p>

    <div class="new-users-statistic__blocks">
      <div
        v-for="block in NEW_USERS_STATISTIC_BLOCKS"
        :key="block.id"
        class="new-users-statistic__block block"
      >
        <div class="block__icon">
          <span :class="`icon-${block.icon}`" />
        </div>

        <div class="block__info">
          <h6>{{ block.label }}</h6>
          <p>{{ block.value }}</p>
        </div>
      </div>
    </div>

    <div class="new-users-statistic__update">
      <p class="new-users-statistic__update-date">
        Последнее обновление: {{ updatedDate }}
      </p>
      <p class="new-users-statistic__update-rate">
        Статистика обновляется каждые 4 часа
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.new-users-statistic {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 8px;

    > h2 {
      @include body-1-bold;
      color: $dark-primary;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .icon-info {
      height: 16px;
      width: 16px;
      background-color: $dark-primary;
    }
  }

  &__button-info {
    display: flex;
  }

  &::v-deep .v-popover {
    display: inline-flex;
  }

  &__popover-text {
    @include text-2;
    color: $dark-primary;
  }

  &__caption {
    @include text-2;
    color: $dark-fifth;
    margin-bottom: 8px;
  }

  &__blocks {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    margin-bottom: 16px;
  }

  .block {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: $light-primary;
    box-shadow: 0 0 30px 0 rgba(169, 169, 169, 0.15);
    padding: 8px 12px;
    gap: 8px;

    &__icon {
      height: 40px;
      width: 40px;
      background-color: $light-third;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      > span {
        width: 24px;
        height: 24px;
        background-color: $dark-primary;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: 4px;

      > h6 {
        @include caption-1;
        color: $dark-third;
      }

      > p {
        @include body-1-bold;
        color: $dark-primary;
      }
    }
  }

  &__update-date {
    @include text-2;
    color: $dark-primary;
    margin-bottom: 4px;
  }

  &__update-rate {
    @include caption-1;
    color: $dark-fifth;
  }
}

@media (max-width: 1023px) {
  .new-users-statistic {
    .block {
      &__info {
        gap: 2px;
      }
    }
  }
}

@media (max-width: 768px) {
  .new-users-statistic {
    &__blocks {
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }
  }
}

@media (max-width: 425px) {
  .new-users-statistic {
    &__title {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>
