<script>
import { PUBLIC_ACTIVITIES_BANNERS } from "@/package/const/public-activities-banners";
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import { mapState } from "vuex";

export default {
  components: {
    MainButtonIcon,
    ButtonRedirect,
  },

  data() {
    return {
      PUBLIC_ACTIVITIES_BANNERS,
      wrapperScrollWidth: 0,
      articleWidth: 0,
      x: 0,
      wrapperOffsetWidth: 0,
    };
  },

  watch: {
    innerWidth() {
      this.createVariables();

      document
        .getElementsByClassName("public-activities-banner__wrapper")[0]
        .scroll({ top: 0, left: 0, behavior: "smooth" });
    },
  },

  computed: {
    ...mapState({
      innerWidth: (state) => state.innerWidth,
    }),
  },

  methods: {
    redirectToChat(url) {
      window.open(url);
    },

    nextSlide() {
      this.x =
        this.x + this.articleWidth >= this.wrapperScrollWidth
          ? 0
          : this.x + this.articleWidth;

      document
        .getElementsByClassName("public-activities-banner__wrapper")[0]
        .scroll({ top: 0, left: this.x, behavior: "smooth" });
    },

    prevSlide() {
      this.x =
        this.x - this.articleWidth < 0
          ? this.wrapperScrollWidth - this.articleWidth
          : this.x - this.articleWidth;

      document
        .getElementsByClassName("public-activities-banner__wrapper")[0]
        .scroll({ top: 0, left: this.x, behavior: "smooth" });
    },

    createVariables() {
      this.$nextTick(() => {
        this.wrapperScrollWidth = document.getElementsByClassName(
          "public-activities-banner__wrapper"
        )[0].scrollWidth;
        this.articleWidth = document.getElementsByTagName(
          "article"
        )[0].offsetWidth;
        this.wrapperOffsetWidth = document.getElementsByClassName(
          "public-activities-banner__wrapper"
        )[0].offsetWidth;
      });
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.createVariables();
    });
  },
};
</script>

<template>
  <section class="main-tariffs__carousel">
    <MainButtonIcon class="navigate-button" @click="prevSlide">
      <span class="icon-arrow-medium" />
    </MainButtonIcon>

    <div class="public-activities-banner__wrapper">
      <article
        v-for="(banner, index) in PUBLIC_ACTIVITIES_BANNERS"
        :key="index"
      >
        <div class="public-activities-banner">
          <div class="public-activities-banner__content content">
            <p class="content__name">{{ banner.name }}</p>
            <h4>{{ banner.title }}</h4>
            <p class="content__text">{{ banner.text }}</p>

            <ButtonRedirect
              class="public-activities-banner__link"
              :arrow-position="'right'"
              :text="'Открыть в Telegram'"
              :button-style="'bold'"
              @click="redirectToChat(banner.link)"
            />
          </div>

          <img :src="banner.image" alt="" />
        </div>
      </article>
    </div>

    <MainButtonIcon class="navigate-button" @click="nextSlide">
      <span class="icon-arrow-medium" />
    </MainButtonIcon>
  </section>
</template>

<style lang="scss" scoped>
.main-tariffs__carousel {
  position: relative;

  article {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.navigate-button {
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: $light-primary;

  &:first-of-type {
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);

    span {
      transform: rotate(180deg);
    }
  }

  &:last-of-type {
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
  }
}

.icon-arrow-medium {
  width: 23px;
  height: 23px;
  background: $dark-primary;
}

.public-activities-banner {
  width: 100%;
  border-radius: 8px;
  background-color: $gold-main;
  padding: 16px 32px;
  position: relative;
  min-height: 180px;

  &__wrapper {
    display: flex;
    overflow: hidden;
    z-index: 1;
    transition: all 2s ease-out;
  }

  .content {
    max-width: calc(100% - 213px);

    &__name {
      @include caption-1-bold;
      color: $dark-primary;
      margin-bottom: 16px;
    }

    > h4 {
      @include body-1-bold;
      color: $dark-primary;
      margin-bottom: 8px;
    }

    &__text {
      @include text-2;
      color: $dark-primary;
      margin-bottom: 24px;
    }
  }

  > img {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 213px;
  }
}

@media (max-width: 900px) {
  .public-activities-banner {
    padding: 24px;
    min-height: 146px;

    .content {
      max-width: calc(100% - 173px);

      &__text {
        margin-bottom: 16px;
      }
    }

    > img {
      width: 173px;
    }
  }
}

@media (max-width: 575px) {
  .public-activities-banner {
    padding: 20px;

    .content {
      max-width: 100%;
    }

    > img {
      display: none;
    }
  }
}

@media (max-width: 375px) {
  .public-activities-banner {
    padding: 16px;
  }
}
</style>
