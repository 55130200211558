<script>
import getTariffsListOfBinaryAccount from "@/package/usecases/get-tariffs-list-of-binary-account";
import getTariffFromLeg from "@/package/usecases/get-tariff-from-leg";

export default {
  props: {
    tariffsOfBinaryAccount: {
      type: Object,
      required: true,
    },
  },

  computed: {
    tariffs() {
      return getTariffsListOfBinaryAccount(this.tariffsOfBinaryAccount);
    },
  },

  methods: {
    getTariffCountFromLeg(leg, tariff) {
      return getTariffFromLeg(leg, tariff).count;
    },
  },
};
</script>

<template>
  <div class="tariffs-of-binary-account">
    <p class="tariffs-of-binary-account__title">Тарифы в структуре:</p>

    <div class="tariffs-of-binary-account__wrapper">
      <div class="tariff">
        <p class="column-title">Тариф</p>
        <p
          v-for="tariff in tariffs"
          :key="tariff.tariffCode"
          class="column-item"
        >
          {{ tariff.tariffName }}
        </p>
      </div>

      <div class="leg">
        <p class="column-title">L</p>
        <p
          v-for="tariff in tariffs"
          :key="tariff.tariffCode"
          class="column-item"
        >
          {{ getTariffCountFromLeg(tariffsOfBinaryAccount.left, tariff) }}
        </p>
      </div>
      <div class="leg leg_margin-left">
        <p class="column-title">R</p>
        <p
          v-for="tariff in tariffs"
          :key="tariff.tariffCode"
          class="column-item"
        >
          {{ getTariffCountFromLeg(tariffsOfBinaryAccount.right, tariff) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tariffs-of-binary-account {
  &__title {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: 16px;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: minmax(104px, 140px) 1fr 1fr;
    @include text-2;
    column-gap: 8px;
  }

  .column-title {
    color: $light-sixth;
    margin-bottom: 16px;
  }

  .column-item {
    color: $dark-primary;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .leg {
    &_margin-left {
      margin-left: 8px;
    }
  }
}
</style>
