<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },

    label: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    type: {
      type: String,
      default: "text",
    },

    required: {
      type: Boolean,
      default: false,
    },

    requiredIcon: {
      type: Boolean,
      default: false,
    },

    mask: {
      type: [String, Array, Object],
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: "",
    },

    background: {
      type: String,
      default: "grey",
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    useDashedBorder: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isOffsetRight() {
      return !!this.$slots.icon;
    },
  },
};
</script>

<template>
  <div class="main-input">
    <label v-if="label" for="input">
      {{ label }} <span v-if="requiredIcon">*</span>
    </label>

    <input
      id="input"
      v-maska="mask"
      class="main-input__input"
      :class="[
        {
          'main-input__input_error': error,
          'main-input__input_dashed': useDashedBorder,
        },
        `main-input__input_${background}`,
        { 'main-input__input_offset-right': isOffsetRight },
      ]"
      :value="value"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :readonly="readonly"
      @input="$emit('input', $event.target.value)"
      @focusout="$emit('focusout', $event.target.value)"
    />

    <span
      v-if="error"
      class="main-input__error"
      :class="{ 'main-input__error_margin': errorMessage }"
    >
      {{ errorMessage }}
    </span>

    <slot></slot>

    <slot name="icon"></slot>
  </div>
</template>

<style lang="scss" scoped>
.main-input {
  position: relative;
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: $space-l;
  }

  &__input {
    background-color: $background-grey;
    color: $dark-primary;
    border-radius: 6px;
    height: 48px;
    width: calc(100% - 2px);
    padding-left: $space-m;
    padding-right: $space-s;
    outline: none;
    z-index: 99;
    position: relative;
    border: 1px solid $light-fifth;
    @include body-1;
    text-overflow: ellipsis;

    &::placeholder {
      color: $light-sixth;
    }

    &:focus {
      border: 1px solid $gold-main;
    }

    &:read-only {
      border: none !important;

      &:hover {
        background-color: $light-second;
      }
    }

    // hide arrows from input number
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    &_error {
      border: 1px solid $red !important;
    }

    &_white {
      background-color: $light-primary;
    }

    &_dashed {
      border: none;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23E9E9E9FF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }

    &_offset-right {
      padding-right: $space-xxl;
    }
  }

  label {
    display: block;
    margin-bottom: $space-s;
    @include text-2;
    color: $dark-sixth;

    > span {
      color: $red;
    }
  }

  &__error {
    display: block;
    @include caption-1;
    color: $red;

    &_margin {
      margin-top: 8px;
      margin-left: 4px;
    }
  }
}
</style>
