<script>
import { mapState } from "vuex";
import * as TWEEN from "@tweenjs/tween.js";

export default {
  name: "RcMarketShoppingProgress",

  props: {
    done: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      number: 0,
      isShowProgress: false,
    };
  },

  computed: {
    ...mapState({
      target: (state) => state.market.marketTarget,
      spent: (state) => state.market.marketSpent,
    }),

    maxWidth() {
      if (this.spent === 0) {
        return "max-width: 5px";
      }

      return ` max-width: ${(this.spent * 100) / this.target}%; `;
    },
  },

  methods: {
    tween: function (startValue, endValue) {
      let vm = this;

      function animate() {
        if (TWEEN.update()) {
          requestAnimationFrame(animate);
        }
      }

      new TWEEN.Tween({ number: startValue })
        .to({ number: endValue }, 1500)
        .onUpdate(function (object) {
          vm.number = new Intl.NumberFormat("ru-RU", {
            style: "decimal",
          }).format(object.number.toFixed(0));
        })
        .start();

      animate();
    },

    toMarket() {
      if (this.done) {
        return;
      }

      window.open(`${process.env.VUE_APP_MARKET_URL}`);
    },
  },

  mounted() {
    if (this.done) {
      return;
    }

    this.$nextTick(() => {
      let callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              if (this.number === 0) {
                this.tween(0, this.spent);
              }

              this.isShowProgress = true;
            }, 500);
          }
        });
      };

      let options = {
        root: document.getElementById("shopping__progress-bar"),
        rootMargin: "20px",
        threshold: 1,
      };

      let observer = new IntersectionObserver(callback, options);

      let target = document.getElementsByClassName("progress-bar")[0];
      observer.observe(target);
    });
  },
};
</script>

<template>
  <section
    class="shopping"
    :class="{ shopping_disabled: done }"
    @click="toMarket"
  >
    <div
      class="shopping__icon"
      :class="`shopping${done ? '_disabled' : ''}__icon`"
    >
      <span class="icon-chart" />
    </div>
    <p>Совершите покупки в RC MARKET</p>
    <span :class="`${done ? 'icon-check-mark' : 'icon-arrow-medium'}`" />

    <div v-if="!done" class="shopping__progress">
      <p>
        <span>Сумма покупок:</span>
        <output>{{ number }} из {{ target }} RC</output>
      </p>

      <div class="shopping__progress-bar progress-bar">
        <div
          v-if="isShowProgress"
          class="progress-bar__progress"
          :style="maxWidth"
        />
      </div>
    </div>
    <i v-for="i in 50" :key="i" />
  </section>
</template>

<style scoped lang="scss">
.shopping {
  display: grid;
  grid-template-columns: 32px 1fr 16px;
  column-gap: 8px;
  row-gap: 16px;
  align-items: center;

  &__icon {
    width: 32px;
    height: 32px;
    background: #fff0c7;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon-chart {
    width: 16px;
    height: 16px;
    background: $dark-primary;
  }

  &_disabled {
    &:hover {
      box-shadow: none !important;
      cursor: default !important;
    }

    &__icon {
      background: $light-second;

      .icon-chart {
        background: $dark-sixth;
      }
    }

    p {
      color: $dark-sixth;
    }
  }

  p {
    @include text-2;
  }

  .icon-arrow-medium {
    width: 16px;
    height: 16px;
    background: $dark-sixth;
  }

  &__progress {
    grid-column: -1/1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    gap: 8px;
    border-radius: 8px;

    > p {
      width: 100%;
      display: flex;
      justify-content: space-between;
      @include text-2-bold;
      gap: 8px;

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 120px);
        overflow: hidden;
      }

      output {
        white-space: nowrap;
        min-width: max-content;
      }
    }
  }
}

.progress-bar {
  width: 100%;
  height: 4px;
  border-radius: 8px;
  background: $light-third;

  &__progress {
    height: 100%;
    border-radius: 8px;
    background: $gold-main;
    animation: animationProgress 3s ease-in-out;
  }
}

.icon-check-mark {
  width: 16px;
  height: 16px;
  background: $money-cv;
}

@keyframes animationProgress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
