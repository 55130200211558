export default function getTariffFromLeg(leg, tariff) {
  if (
    leg &&
    leg.find((item) => {
      return item.tariff === tariff.tariffCode;
    }) !== undefined
  ) {
    return leg.find((item) => {
      return item.tariff === tariff.tariffCode;
    });
  }

  return {
    ...tariff,
    count: "-",
  };
}
