export const PUBLIC_ACTIVITIES_BANNERS = [
  {
    name: "Русское Деловое Общество",
    title:
      "Присоединяйтесь и управляйте бизнесом вместе с лучшими в своем деле!",
    text: "Присоединяйся к нашему Telegram-каналу",
    image: require("../../assets/images/public_activities_1.png"),
    link: "https://t.me/rdo78",
  },
  {
    name: "Газета о России",
    title:
      "Подписывайтесь и получайте только честные и позитивные новости каждый день!",
    text: "Присоединяйся к нашему Telegram-каналу",
    image: require("../../assets/images/public_activities_2.png"),
    link: "https://t.me/+yb8FJaRsXDc2YmEy ",
  },
  {
    name: "Фонд Марии Михайловой",
    title:
      "Присоединяйтесь и следите за новостями фонда. Работаем на благо людей России!",
    text: "Присоединяйся к нашему Telegram-каналу",
    image: require("../../assets/images/public_activities_3.png"),
    link: "https://t.me/FMMSPb",
  },
];
