<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },

    options: {
      type: Array,
      required: true,
    },

    value: {
      type: Array,
    },
  },
};
</script>

<template>
  <div class="main-input-radio-group">
    <label
      v-for="option in options"
      :key="option.id"
      class="main-input-radio-group__item item"
    >
      <input
        type="radio"
        :name="name"
        :value="option"
        :checked="value.find((item) => item.id === option.id)"
        @change="$emit('input', [option])"
      />
      <span class="item__radio"></span>
      <span class="item__text">{{ option.text }}</span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.main-input-radio-group {
  &__item {
    display: grid;
    align-items: center;
    grid-template-columns: 16px 1fr;
    gap: $space-s;

    &:not(:last-child) {
      margin-bottom: $space-s;
    }
  }

  .item {
    cursor: pointer;
    position: relative;

    input {
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    &__radio {
      width: 16px;
      height: 16px;
      border: 2px solid $dark-primary;
      border-radius: 100%;
      position: relative;
      transition: border 0.1s;
    }

    &__radio::before,
    &__radio::after {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: inherit;
      position: absolute;
      transform: scale(0);
      transform-origin: center center;
    }

    &__radio:before {
      background: $dark-primary;
      opacity: 0;
      transition: 0.3s;
    }

    &__radio::after {
      background: $dark-primary;
      opacity: 0.4;
      transition: 0.6s;
    }

    &__text {
      color: $dark-primary;
      @include text-2;
      cursor: pointer;
      max-width: 85%;
    }

    input:checked ~ .item__radio::before {
      opacity: 1;
      transform: scale(0.6);
    }

    input:not(:checked):hover ~ .item__radio {
      border: 3px solid $dark-primary;
    }

    input:hover ~ .item__radio:before,
    input:focus ~ .item__radio:before {
      background: $dark-primary;
    }
  }
}
</style>
