import { render, staticRenderFns } from "./NewsPreview.vue?vue&type=template&id=3153a118&scoped=true"
import script from "./NewsPreview.vue?vue&type=script&lang=js"
export * from "./NewsPreview.vue?vue&type=script&lang=js"
import style0 from "./NewsPreview.vue?vue&type=style&index=0&id=3153a118&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3153a118",
  null
  
)

export default component.exports