<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },

    text: {
      type: String,
      required: true,
    },

    balance: {
      type: Number,
      required: true,
    },
  },

  computed: {
    formattedBalance() {
      return `${this.balance.toLocaleString()} RC`;
    },
  },
};
</script>

<template>
  <div class="balance-statistic-item">
    <div class="balance-statistic-item__image">
      <span :class="`icon-coin-${index}`"></span>
    </div>

    <div class="balance-statistic-item__info">
      <h5>{{ formattedBalance }}</h5>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-statistic-item {
  display: flex;
  align-items: center;
  gap: 16px;

  &__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $gold-main;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      width: 24px;
      height: 24px;
      background-color: $dark-primary;
    }
  }

  &__info {
    h5 {
      @include body-1-bold;
      color: $dark-primary;
      margin-bottom: 4px;
    }

    p {
      @include text-2;
      color: $dark-fifth;
    }
  }
}
</style>
