<script>
import { getMonth, getYear } from "date-fns";
import { mapState, mapActions } from "vuex";
import MainButton from "../helpers/MainButton.vue";
import BalanceStatisticItem from "./BalanceStatisticItem.vue";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";

const MONTHS = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export default {
  components: { MainButtonIcon, BalanceStatisticItem, MainButton },

  data() {
    return {
      isShowStatisticList: true,
    };
  },

  computed: {
    ...mapState({
      incomeBalance: (state) => state.balance.incomeBalance,
    }),

    currentMonth() {
      return MONTHS[getMonth(new Date())].toLowerCase();
    },

    currentYear() {
      return getYear(new Date());
    },
  },

  methods: {
    ...mapActions({
      loadIncomeBalance: "balance/loadIncomeBalance",
      downloadIncomeBalance: "balance/downloadIncomeBalance",
    }),

    setShowStatisticList() {
      localStorage.setItem(
        "isShowStatisticList",
        `${!this.isShowStatisticList}`
      );

      this.isShowStatisticList = !this.isShowStatisticList;
    },
  },

  mounted() {
    if (localStorage.getItem("isShowStatisticList") === "true") {
      this.isShowStatisticList = true;
    } else if (localStorage.getItem("isShowStatisticList") === "false") {
      this.isShowStatisticList = false;
    } else {
      this.isShowStatisticList = true;
    }

    this.loadIncomeBalance();
  },
};
</script>

<template>
  <div v-if="incomeBalance" class="balance-statistic">
    <div class="balance-statistic__title">
      <h5>Заработок по периодам</h5>

      <MainButtonIcon
        class="balance-statistic__button"
        @click="setShowStatisticList"
      >
        <i :class="isShowStatisticList ? 'icon-show' : 'icon-hide'" />
      </MainButtonIcon>
    </div>

    <div
      :class="[
        `balance-statistic__wrapper`,
        { 'balance-statistic__wrapper_opened': isShowStatisticList },
      ]"
    >
      <div class="balance-statistic__list">
        <BalanceStatisticItem
          :index="1"
          :text="`Заработано за ${currentMonth} ${currentYear}`"
          :balance="incomeBalance.income.monthly_total"
        />
        <BalanceStatisticItem
          :index="2"
          :text="`Заработано с начала ${currentYear}`"
          :balance="incomeBalance.income.yearly_total"
        />
        <BalanceStatisticItem
          :index="3"
          :text="'Заработано за все время'"
          :balance="incomeBalance.income.all_total"
        />
      </div>

      <MainButton
        v-if="incomeBalance.income.all_total > 0"
        class="balance-statistic__share"
        :title="'Поделиться статистикой'"
        :color="'blue'"
        @click="downloadIncomeBalance"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.balance-statistic {
  padding: 24px;
  border-radius: 8px;
  background-color: $light-primary;
  width: 100%;
  max-width: 337px;
  height: fit-content;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > h5 {
      @include text-2;
      color: $dark-primary;
    }
  }

  &__button {
    > i {
      width: 20px;
      height: 20px;
      background-color: $dark-sixth;
      transition: background-color 0.05s linear;
    }

    &:hover {
      > i {
        background-color: $dark-primary;
      }
    }
  }

  &__wrapper {
    transition: all 0.4s ease-in-out;
    max-height: 0;
    overflow: hidden;
    opacity: 0;

    &_opened {
      max-height: 400px;
      opacity: 1;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }

  &__share {
    margin-top: 24px;
  }
}

@media (max-width: 1024px) {
  .balance-statistic {
    max-width: none;
  }
}
</style>
