<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },

    expandedText: {
      type: String,
      required: true,
    },

    expanded: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currentButtonText() {
      return this.expanded ? this.expandedText : this.text;
    },
  },
};
</script>

<template>
  <button
    class="main-button-expand"
    :class="{ 'main-button-expand_expanded': expanded }"
    @click="$emit('update:expanded', !expanded)"
  >
    {{ currentButtonText }}

    <span class="main-button-expand__arrow"></span>
  </button>
</template>

<style lang="scss" scoped>
.main-button-expand {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &_expanded {
    .main-button-expand__arrow {
      transform: rotate(-90deg);
    }
  }

  &__arrow {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: $dark-third;
    mask: url("../../assets/icons/arrow_outline.svg");
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    transform: rotate(90deg);
    transition: transform 0.15s linear;
    margin-left: $space-s;
  }
}
</style>
