<script>
export default {
  emits: ["close"],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    showIconClose: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    showModal() {
      if (this.showModal) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    },
  },

  methods: {
    closeModal(classList) {
      if (classList.contains("main-modal")) {
        this.$emit("close");
      }
    },
  },
};
</script>

<template>
  <transition name="fade">
    <div
      v-if="showModal"
      class="main-modal"
      @click="closeModal($event.target.classList)"
    >
      <div class="main-modal__container">
        <span
          v-if="showIconClose"
          class="icon-close"
          @click="$emit('close')"
        ></span>

        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.main-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(71, 71, 71, 0.6);
  z-index: 10002;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 16px;
  cursor: pointer;

  &__container {
    background: $light-second;
    width: fit-content;
    height: fit-content;
    max-height: 100%;
    border-radius: 16px;
    padding: 40px;
    position: relative;
    cursor: default;
    overflow-y: auto;

    .icon-close {
      position: absolute;
      top: 12px;
      right: 12px;
      width: 32px;
      height: 32px;
      background-color: $dark-sixth;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .main-modal {
    &__container {
      padding: 24px;
    }

    .icon-close {
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
    }
  }
}

@media (max-width: 500px) {
  .main-modal {
    padding: 32px;

    &__container {
      width: 100%;
      padding: 26px 20px;
    }
  }
}

@media (max-width: 424px) {
  .main-modal {
    padding: 24px;
  }
}

@media (max-width: 374px) {
  .main-modal {
    padding: 16px;
  }
}
</style>
