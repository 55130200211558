<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState({
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),
  },

  methods: {
    ...mapActions({
      changeBinaryAccountMode: "account/changeBinaryAccountMode",
      checkUser: "auth/checkUser",
    }),

    changeMode(mode) {
      const binaryAccountId = this.selectedBinaryAccount.id;

      if (mode !== this.selectedBinaryAccount.mode) {
        this.changeBinaryAccountMode({ binaryAccountId, mode }).then(() => {
          this.checkUser();
        });
      }
    },
  },
};
</script>

<template>
  <div class="leg-selector">
    <p v-if="showTitle" class="leg-selector__title">Предустановка:</p>

    <div class="leg-selector__wrapper">
      <div
        class="leg-selector__left"
        :class="{ selected: selectedBinaryAccount.mode === 'left' }"
        @click="changeMode('left')"
      >
        L
      </div>
      <div
        class="leg-selector__auto"
        :class="{ selected: selectedBinaryAccount.mode === 'auto' }"
        @click="changeMode('auto')"
      >
        Auto
      </div>
      <div
        class="leg-selector__right"
        :class="{ selected: selectedBinaryAccount.mode === 'right' }"
        @click="changeMode('right')"
      >
        R
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.leg-selector {
  &__title {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: $space-m;
  }

  &__wrapper {
    display: flex;
  }

  &__left,
  &__right,
  &__auto {
    width: 33.33%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include text-2;
    color: $dark-primary;
    background-color: $background-grey;
    cursor: pointer;
    transition: background-color 0.2s linear;
  }

  &__left {
    border-radius: 8px 0 0 8px;
    border-right: 1px solid $light-fifth;
  }

  &__right {
    border-radius: 0 8px 8px 0;
    border-left: 1px solid $light-fifth;
  }

  .selected {
    background-color: $dark-primary;
    color: $background-grey;
  }
}
</style>
