<template>
  <router-link
    :to="{ name: 'News', params: { id: news.id } }"
    class="news-preview"
  >
    <div class="news-preview__img">
      <img :src="news.thumbnail" alt="" />
    </div>

    <div class="news-preview__info">
      <h5>{{ news.title }}</h5>
      <span>{{ new Date(news.published_at).toLocaleString() }}</span>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.news-preview {
  width: 100%;
  background-color: $background-white;
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  max-height: 305px;
  display: grid;
  grid-template-rows: 188px 1fr;

  &__img {
    height: 188px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      min-height: 100%;
      min-width: 100%;
    }
  }

  &__info {
    padding: 16px;
    display: flex;
    flex-direction: column;

    h5 {
      @include body-1-bold;
      color: $dark-primary;
      margin-bottom: $space-xs;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    span {
      display: block;
      @include text-2;
      color: $dark-fifth;
      margin-top: auto;
    }
  }
}

@media (max-width: 789px) {
  .news-preview {
    max-height: none;
    grid-template-rows: 270px 1fr;

    &__img {
      height: 270px;
    }
  }
}

@media (max-width: 575px) {
  .news-preview {
    grid-template-rows: 202px 1fr;

    &__img {
      height: 202px;
    }
  }
}

@media (max-width: 424px) {
  .news-preview {
    grid-template-rows: 184px 1fr;

    &__img {
      height: 184px;
    }
  }
}

@media (max-width: 424px) {
  .news-preview {
    grid-template-rows: 153px 1fr;

    &__img {
      height: 153px;
    }
  }
}
</style>
