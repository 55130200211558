<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    height: {
      type: Number,
      default: 100,
    },
  },
};
</script>

<template>
  <div class="main-textarea">
    <label>{{ title }}</label>

    <textarea
      :value="value"
      :placeholder="placeholder"
      :style="{ height: height + 'px' }"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<style lang="scss" scoped>
.main-textarea {
  textarea {
    @include body-1;
    color: $dark-primary;
    border: 1px solid #e9e9e9;
    border-radius: $space-s;
    background-color: $light-second;
    padding: 12px $space-m;
    width: 100%;
    height: 100%;
    resize: none;

    &::placeholder {
      color: $light-sixth;
    }

    &:focus {
      outline: 1px solid $gold-main;
    }
  }
}
</style>
