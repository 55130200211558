<script>
import IconBase from "@/components/helpers/IconBase";
import IconPollChosen from "@/components/icons/IconPollChosen";

export default {
  components: { IconPollChosen, IconBase },

  props: {
    poll: {
      type: Object,
      required: true,
    },
  },

  methods: {
    calculateLineWidth(numberOfAnswers) {
      return Math.round((numberOfAnswers / this.poll.total_answers) * 100);
    },
  },
};
</script>

<template>
  <div class="poll-results">
    <template v-if="poll.variants.length > 0">
      <div
        v-for="variant in poll.variants"
        :key="variant.id"
        class="poll-results__item item"
      >
        <p class="item__text">{{ variant.text }}</p>

        <div class="item__result">
          <div class="item__line">
            <div
              class="item__inner-line"
              :class="{ 'item__inner-line_chosen': variant.chosen }"
              :style="{
                width: `${calculateLineWidth(variant.answers_count)}%`,
              }"
            >
              <icon-base
                v-if="variant.chosen"
                icon-name="icon-poll-chosen"
                icon-fill="#2f2f2f"
                height="10"
                width="10"
                view-box-width="10"
                view-box-height="10"
              >
                <icon-poll-chosen />
              </icon-base>
            </div>
          </div>
          <p class="item__number">
            {{ calculateLineWidth(variant.answers_count) }} %
          </p>
        </div>
      </div>

      <div v-if="poll.custom" class="poll-results__item item">
        <p class="item__text">Свой вариант — {{ poll.custom }}</p>

        <div class="item__result">
          <div class="item__line">
            <div
              class="item__inner-line item__inner-line_chosen"
              :style="{ width: `${calculateLineWidth(poll.custom_answers)}%` }"
            >
              <icon-base
                icon-name="icon-poll-chosen"
                icon-fill="#2f2f2f"
                height="10"
                width="10"
                view-box-width="10"
                view-box-height="10"
              >
                <icon-poll-chosen />
              </icon-base>
            </div>
          </div>
          <p class="item__number">
            {{ calculateLineWidth(poll.custom_answers) }} %
          </p>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="poll-results__item item item_text-answer">
        <h6 class="item__title">
          <icon-base
            icon-name="icon-poll-chosen"
            icon-fill="#2f2f2f"
            height="10"
            width="10"
            view-box-width="10"
            view-box-height="10"
          >
            <icon-poll-chosen />
          </icon-base>
          Ваш ответ
        </h6>

        <p class="item__text">{{ poll.custom }}</p>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.poll-results {
  &__item {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: $space-m;
    }
  }

  .item {
    &__text {
      margin-bottom: $space-s;
    }

    &__result {
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 50px;
      grid-column-gap: 12px;
    }

    &__line {
      background-color: $light-second;
      height: 20px;
      border-radius: 8px;
      overflow: hidden;
    }

    &__inner-line {
      background-color: $light-fifth;
      height: 20px;
      width: 30%;
      display: flex;
      align-items: center;

      &_chosen {
        background-color: $gold-secondary;
      }

      .icon-base {
        margin-left: $space-s;
      }
    }

    &__number {
      @include body-1-bold;
      color: $dark-primary;
    }

    &_text-answer {
      .item__title {
        @include text-2-bold;
        color: $dark-primary;
        margin-bottom: $space-s;
      }

      .item__text {
        @include body-1;
        color: $dark-primary;
        margin-bottom: 0;
      }
    }
  }
}
</style>
