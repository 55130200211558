<script>
import { mapState } from "vuex";
import IconBase from "@/components/helpers/IconBase.vue";
import MarketModal from "@/components/dashboard/MarketModal.vue";
const IconAccountPositionMaster = () =>
  import("@/components/icons/IconAccountPositionMaster");
const IconAccountPositionLeft = () =>
  import("@/components/icons/IconAccountPositionLeft");
const IconAccountPositionRight = () =>
  import("@/components/icons/IconAccountPositionRight");

export default {
  name: "TariffPaymentProgress",

  components: {
    IconBase,
    MarketModal,
    IconAccountPositionLeft,
    IconAccountPositionRight,
    IconAccountPositionMaster,
  },

  props: {
    done: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      texts: {
        master: "Внесите абонентскую плату на основном аккаунте",
        left: "Внесите абонентскую плату на левом аккаунте",
        right: "Внесите абонентскую плату на правом аккаунте",
      },
    };
  },

  computed: {
    ...mapState({
      position: (state) => state.auth.selectedBinaryAccount.position,
      id: (state) => state.auth.selectedBinaryAccount.id,
      subscriptions: (state) => state.market.subscriptions,
    }),

    defineAccountPositionIcon() {
      return `icon-account-position-${this.position}`;
    },

    text() {
      return this.texts[this.position];
    },

    fillColor() {
      return this.done ? "#969696" : "#212121";
    },
  },

  methods: {
    toSubscription() {
      if (this.done) {
        return;
      }

      this.$router.push({ name: "Subscription" });
    },
  },
};
</script>

<template>
  <section
    class="tariff-payment-progress"
    :class="{ 'tariff-payment-progress_disabled': done }"
    @click="toSubscription"
  >
    <div
      class="tariff-payment-progress__icon"
      :class="`tariff-payment-progress${done ? '_disabled' : ''}__icon`"
    >
      <icon-base
        icon-name="icon-account-position"
        :icon-fill="fillColor"
        width="16"
        height="16"
      >
        <component :is="defineAccountPositionIcon" />
      </icon-base>
    </div>
    <p>{{ text }}</p>
    <span :class="`${done ? 'icon-check-mark' : 'icon-arrow-medium'}`" />

    <i v-for="i in 50" :key="i" />
  </section>
</template>

<style scoped lang="scss">
.tariff-payment-progress {
  display: grid;
  grid-template-columns: 32px 1fr 16px;
  column-gap: 8px;
  align-items: center;
  transition: box-shadow 0.1s ease-out;

  &__icon {
    background: #fff0c7;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p {
    @include text-2;
  }

  &_disabled {
    &:hover {
      box-shadow: none !important;
      cursor: default !important;
    }

    p {
      color: $dark-sixth;
    }

    &__icon {
      background: $light-second;
    }
  }
}

.icon-check-mark {
  width: 16px;
  height: 16px;
  background: $money-cv;
}

.icon-arrow-medium {
  width: 16px;
  height: 16px;
  background: $dark-sixth;
}
</style>
